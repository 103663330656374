import { useCallback, useEffect, useRef, useState } from 'react';

export default function ViewPort ( props ) {

  const myRef = useRef();
  const [ inViewPort, setInViewPort ] = useState( props?.viewPort ? props.viewPort : false );

  // CHECK IF WHOLE ELEMENT IS IN VIEWPORT
  // const inWholeViewPortUpdate = useCallback( ( myRef, windowWidth, windowHeight ) => {
  //   let refDiv;
  //   if ( myRef.current ) {
  //     refDiv = myRef.current.getBoundingClientRect();
  //     if ( refDiv.top >= 0 && refDiv.left >= 0 && refDiv.right <= windowWidth && refDiv.bottom <= ( windowHeight - refDiv.bottom ) ) {
  //       setInViewPort( true );
  //     } else {
  //       setInViewPort( false );
  //     }
  //   }
  // }, []);

  // CHECK IF ANY PART OF THE ELEMENT IS IN VIEWPORT
  const inAnyViewPortUpdate = useCallback( ( myRef, windowWidth, windowHeight, windowPageXOffset, windowPageYOffset ) => {
    let refDiv;

    if ( myRef.current ) {
      refDiv = myRef.current;

      let top = refDiv.offsetTop;
      let left = refDiv.offsetLeft;
      const width = refDiv.offsetWidth;
      const height = refDiv.offsetHeight;

      while ( refDiv.offsetParent ) {
        refDiv = refDiv.offsetParent;
        top += refDiv.offsetTop;
        left += refDiv.offsetLeft;
      }

      if ( top < ( windowPageYOffset + windowHeight ) &&
        left < ( windowPageXOffset + windowWidth ) &&
        ( top + height ) > windowPageYOffset &&
        ( left + width ) > windowPageXOffset
      ) {
        setInViewPort( true );
      }
      // else {
      //     setInViewPort(false);
      // }
    }
  }, []);

  useEffect( () => {
    if ( document !== undefined && window !== undefined ) {
      inAnyViewPortUpdate( myRef, window.innerWidth, window.innerHeight, window.pageXOffset, window.pageYOffset );
      window.addEventListener( 'scroll', function () {
        inAnyViewPortUpdate( myRef, this.innerWidth, this.innerHeight, this.pageXOffset, this.pageYOffset );
      });
    }
  }, [inAnyViewPortUpdate]);

  if ( !inViewPort ) {
    return (
      <div
        id={props.id ? props.id : ''}
        ref={myRef}
        className={props?.parentClassName}
      >
        {props?.skeletonItem}
      </div>
    )
  }

  return (
    <>
      {props.children}
    </>
  );
}
