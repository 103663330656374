import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';

import Checkers from '../../../helpers/class/Checkers';

export default function CalendarArrival ( props ) {
  if ( !Checkers.isValidObj( props, 'calendar' ) ) {
    return null;
  }
  return (
    <>
      <div className="hidden-xs hidden-sm">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={props.calendar.customTheme}>
            <DesktopDateRangePicker
              startText="Add dates"
              calendars={1}
              value={props.calendar.value}
              minDate={new Date()}
              inputFormat="dd/MM/yyyy"
              // disableCloseOnSelect={false}
              showDaysOutsideCurrentMonth={true}
              onChange={( newValue ) => {
                props.calendar.setValue( newValue );
                if ( newValue[0] != null ) {
                  props.calendar.arrival = new Date( newValue[0]).getDate() + '/' + ( new Date( newValue[0]).getMonth() + 1 ) + '/' + new Date( newValue[0]).getFullYear();
                  props.calendar.setArrival( props.calendar.arrival );
                }
                if ( newValue[1] != null ) {
                  props.calendar.departure = new Date( newValue[1]).getDate() + '/' + ( new Date( newValue[1]).getMonth() + 1 ) + '/' + new Date( newValue[1]).getFullYear();
                  props.calendar.setDeparture( props.calendar.departure );
                }
              }}
              renderInput={( props ) => (
                <React.Fragment>
                  <TextField {...props} autoComplete={'off'}/>
                </React.Fragment>
              )}
            />
          </ThemeProvider>
        </LocalizationProvider>
      </div>
      <div className="visible-xs visible-sm visible-md">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={props.calendar.customTheme}>
            <MobileDateRangePicker
              startText="Add dates"
              // clearable={true}
              // clearText={'Clear'}
              showToolbar={false}
              value={props.calendar.value}
              minDate={new Date()}
              inputFormat="dd/MM/yyyy"
              // disableCloseOnSelect={true}
              showDaysOutsideCurrentMonth={true}
              onChange={( newValue ) => {
                props.calendar.setValue( newValue );
                if ( newValue[0] != null ) {
                  props.calendar.arrival = new Date( newValue[0]).getDate() + '/' + ( new Date( newValue[0]).getMonth() + 1 ) + '/' + new Date( newValue[0]).getFullYear();
                  props.calendar.setArrival( props.calendar.arrival );
                }
                if ( newValue[1] != null ) {
                  props.calendar.departure = new Date( newValue[1]).getDate() + '/' + ( new Date( newValue[1]).getMonth() + 1 ) + '/' + new Date( newValue[1]).getFullYear();
                  props.calendar.setDeparture( props.calendar.departure );
                }
              }}
              renderInput={( startProps ) => (
                <React.Fragment>
                  <TextField {...startProps} autoComplete={'off'}/>
                </React.Fragment>
              )}
            />
          </ThemeProvider>
        </LocalizationProvider>
      </div>
    </>
  )
}
