class Consts {
  static get S3 () {
    return 'https://loggia-cdn.s3.eu-central-1.amazonaws.com/';
  }
  static get S3Folder () {
    return 'shared/';
  }

  static get AppleAppSvg () {
    return this.S3 + this.S3Folder + 'ios_en.svg';
  }
  static get AndroidAppSvg () {
    return this.S3 + this.S3Folder + 'android_en.svg';
  }
  static get W3cValidatorSvg () {
    return this.S3 + this.S3Folder + 'w3c_xhtml_2.0.svg';
  }
  static get WcagValidatorSvg () {
    return this.S3 + this.S3Folder + 'w3c_wcag_1.0.svg';
  }
  static get JumbotronCarouselImage () {
    return 486;
  }
  static get JumbotronGridImage () {
    return 573;
  }
  static get JumbotronStaticImage () {
    return 487;
  }
}

export default Consts;
